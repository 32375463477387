import React, { useState } from 'react';

import PaymentForm from '../components/payment-form/PaymentForm';

const initState = {
    show: false,
    invoices: [],
    newProcessingInvoices: [],
    launchPaymentForm: (invoices) => { },
    closePaymentForm: (newProcessingInvoices) => { },
};

export const PaymentContext = React.createContext(initState);

export const PaymentProvider = (props) => {

    const launchPaymentForm = (invoices) => {
        setState({ 
            ...state, 
            show: true, 
            invoices, 
            newProcessingInvoices: [],
        });
    }

    const closePaymentForm = (newProcessingInvoices) => {
        setState({ 
            ...state, 
            show: false, 
            invoices: [], 
            newProcessingInvoices: newProcessingInvoices 
        });
    }

    const [state, setState] = useState({
        ...initState,
        launchPaymentForm,
        closePaymentForm,
    });

    return (
        <PaymentContext.Provider value={state}>
            {props.children}
            <PaymentForm />
        </PaymentContext.Provider>
    )
}
