import React, { useContext, useEffect, useState } from 'react';

import { Alert, Form, Modal } from 'react-bootstrap';

import './PaymentForm.scss';

import { createProcessingPayment } from '../../apis/paymentsApi';
import { getFormToken } from '../../apis/invoicesApi';

import LoadingSpinner from '../loading-spinner/LoadingSpinner';

import { AuthContext } from '../../providers/authProvider';
import { PaymentContext } from '../../providers/paymentProvider';

import { LOCAL, DEV, PROD, ENVIRONMENT } from '../../utils/environment';

// This PaymentForm is built out separately from the PaymentFormButton
// because of some quirks in Authorize and Bootstrap.
//
// Bootstrap modals are generated programatically when initialized,
// which means they do not exist in the markup until they are shown.
// Furthermore, Bootstrap only allows one modal to be open at a time. 
//
// Our Authorize integration requires three elements to exist in the markup:
// 1) A form used to launch the checkout page. 
// 2) The iFrame used to display the checkout page.
// 3) Some component that can handle iFrame communications.
//
// Due to how transient the markup in a modal is, we have to house this logic 
// in a single component to ensure that all elements are preserved throughout
// the full checkout workflow. 

export default function PaymentForm(props) {
    const { token } = useContext(AuthContext);
    const { show, invoices, closePaymentForm } = useContext(PaymentContext);

    // TODO: Swap out sandbox URL for production URL. 
    const TEST_FORM_URL = 'https://test.authorize.net/payment/payment';
    const PROD_FORM_URL = 'https://accept.authorize.net/payment/payment';

    const FORM_ID = Math.random().toString(16).slice(2);

    const initFormState = {
        formToken: undefined,
        isLoading: true,
        hasError: false,
    };

    const [formTokenReq, setFormTokenReq] = useState(initFormState);

    const [formSize, setFormSize] = useState({});

    const resetAndClosePaymentModal = (newProcessingInvoices = []) => {
        setFormTokenReq(initFormState);
        closePaymentForm(newProcessingInvoices);
    }

    useEffect(() => {
        if (show) {
            // Initialize Authorize communication hooks.
            if (!window.AuthorizeNetIFrame) {
                window.AuthorizeNetIFrame = {};
                window.AuthorizeNetIFrame.onReceiveCommunication = onReceiveAuthorizeCommunication;
            }

            generateFormToken();
        }
    }, [show]);

    useEffect(() => {
        // Re-Initialize Authorize communication hooks with new context.
        if (!window.AuthorizeNetIFrame) {
            window.AuthorizeNetIFrame = {};
        }

        window.AuthorizeNetIFrame.onReceiveCommunication = onReceiveAuthorizeCommunication;
    }, [invoices]);

    useEffect(() => {
        // Once we've successfully generated a form token, 
        // submit the form to launch the Authorize form.
        const { formToken, isLoading } = formTokenReq;
        if (!!formToken && !isLoading) {
            // Once we've successfully recorded the initialized payment, 
            // submit the form to launch the authorize form.
            document.getElementById(`payment-form-${FORM_ID}`).submit();
        }
    }, [formTokenReq]);

    //
    // API Helpers
    //

    function calculateFormURL() {
        switch (ENVIRONMENT) {
            case LOCAL: return TEST_FORM_URL;
            case DEV:   return TEST_FORM_URL;
            case PROD:  return PROD_FORM_URL;
            default:    return TEST_FORM_URL;
        }
    }

    function generateFormToken() {
        return getFormToken({ invoices, token })
            .then(handleFormTokenSuccess, handleFormTokenError)
            .catch(handleFormTokenError);
    }

    const handleFormTokenSuccess = (res) => {
        setFormTokenReq({
            ...formTokenReq,
            formToken: res,
            isLoading: false,
            hasError: false,
        });
    }

    const handleFormTokenError = (err) => {
        setFormTokenReq({
            ...formTokenReq,
            isLoading: false,
            hasError: true,
        });
    }

    function recordPayment(transactionId) {
        createProcessingPayment({ invoices, transactionId, token })
            .then(handleRecordPaymentSuccess, handleRecordPaymentError)
            .catch(handleRecordPaymentError);
    }

    const handleRecordPaymentSuccess = (res) => {
        resetAndClosePaymentModal(invoices);
    }

    const handleRecordPaymentError = (err) => {
        console.error("Failed to record pending payment");
        resetAndClosePaymentModal(invoices);
    }

    // 
    // Authorize iFrame Hooks
    // https://developer.authorize.net/api/reference/features/accept_hosted.html#Transaction_Response
    //

    function onReceiveAuthorizeCommunication(querystr) {
        const params = parseQueryString(querystr);
        switch (params["action"]) {
            case "successfulSave":
                break;
            case "cancel":
                console.log("Cancel payment");
                resetAndClosePaymentModal();
                break;
            case "resizeWindow":
                const w = parseInt(params["width"]);
                const h = parseInt(params["height"]);
                setFormSize({ width: w + "px", height: h + "px" });
                break;
            case "transactResponse":
                let transactionDetails = {};
                let transactionId;

                try {
                    transactionDetails = JSON.parse(params.response);
                    transactionId = transactionDetails.transId;
                    console.log("Authorize transactResponse", transactionDetails);
                } catch (e) {
                    console.error("Could not parse transaction details from Authorize", params);
                }

                // Once the transaction has successfully completed, 
                // record a processing payment.
                recordPayment(transactionId);
                break;
        }
    }

    function parseQueryString(str) {
        const vars = [];
        const arr = str.split('&');
        let pair;
        for (let i = 0; i < arr.length; i++) {
            pair = arr[i].split('=');
            vars.push(pair[0]);
            vars[pair[0]] = unescape(pair[1]);
        }
        return vars;
    }

    //
    // Render Helpers
    //

    function renderPaymentModal() {
        return (
            <Modal
                id="Payment-Modal"
                show={show}
                onHide={closePaymentForm}
                size="lg"
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>Pay Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Alert variant="warning">A 3% Site Fee will be added to all website payment transactions.</Alert>
                    {formTokenReq.isLoading && <LoadingSpinner typ="page" />}
                    {renderIFrame()}
                    {renderPaymentForm()}
                </Modal.Body>
                <Modal.Footer>
                    <span>Payment powered by <a href="https://www.authorize.net/" target="_blank">Authorize.Net</a></span>
                </Modal.Footer>
            </Modal>
        );
    }

    function renderIFrame() {
        return (
            <div id="authorize_iframe"
                className={formTokenReq.isLoading ? "loading" : ""}
                style={{ height: formSize.height }}
            >
                <iframe
                    id="add_payment"
                    className="embed-responsive-item panel"
                    name="add_payment"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    scrolling="no"
                />
            </div>
        )
    }

    function renderPaymentForm() {
        return (
            <Form
                id={`payment-form-${FORM_ID}`}
                method="post"
                action={calculateFormURL()}
                target="add_payment"
            >
                <input type="hidden" name="token" value={formTokenReq.formToken} />
            </Form>
        );
    }

    return (
        <span className={props.className}>
            {renderPaymentModal()}
        </span>
    );
};
