import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Form, Modal } from 'react-bootstrap';

import { PaymentContext } from '../../providers/paymentProvider';

import { prettyPrice } from '../../utils/numberFormatter';
import { formattedDate, formattedTime } from '../../utils/dateFormatter';
import { getFullName } from '../../utils/userFormatter';


export default function PaymentFormButton({
    invoices,
    onInitiatePayment,
    disabled,
    showTermsAndConditions,
    buttonText,
    buttonVariant,
    buttonSize,
    ...props
}) {
    const { launchPaymentForm } = useContext(PaymentContext);

    const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);

    const [confirmationModal, setConfirmationModal] = useState({ show: false });
    const handleConfirmationModalShow = (event) => {
        event.stopPropagation();
        setConfirmationModal({ show: true });
    }
    const handleConfirmationModalClose = () => setConfirmationModal({ show: false });

    const handleLaunchPaymentForm = (event) => {
        event.stopPropagation();

        launchPaymentForm(invoices);
        handleConfirmationModalClose();

        if (typeof onInitiatePayment === 'function') {
            onInitiatePayment();
        }
    }

    //
    // Render Helpers
    //

    function isSingleProcessingInvoice() {
        return invoices.length === 1 && invoices[0].isProcessing;
    }

    function isButtonDisabled() {
        return disabled || calculateTotalDue() <= 0 || (showTermsAndConditions && !hasAgreedToTerms);
    }

    function calculateTotalDue() {
        let totalAmount = 0;
        for (const i of invoices) { totalAmount += i.total_amount_due; }

        return totalAmount;
    }

    function defaultButtonText() {
        return `Pay ${prettyPrice(calculateTotalDue())}`;
    }

    function renderPendingPaymentConfirmationModal() {
        const { processingPayment } = invoices[0];
        const { created_timestamp, user } = processingPayment || {};

        const fullName = getFullName(user);
        const createdTime = formattedTime(created_timestamp);
        const createdDate = formattedDate(created_timestamp);

        return (
            <Modal
                id="Payment-Confirmation-Modal"
                show={confirmationModal.show}
                onHide={handleConfirmationModalClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    It looks like this a payment for this invoice was initiated<br />
                    by {fullName} at {createdTime} on {createdDate}.
                    <br /><br />
                    If this payment attempt was unsuccessful, click "Continue" to initiate a new payment.
                    Otherwise, please <NavLink to="/contact-us">contact our team</NavLink> if you have any additional questions.
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" size="lg" onClick={handleConfirmationModalClose}>
                        Cancel
                    </Button>
                    {renderPayInvoiceButton("outline-warning", "lg", "Continue")}
                </Modal.Footer>
            </Modal>
        );
    }

    function renderTermsAndConditionsCheckbox() {
        return (
            <Form className="mt-2">
                <Form.Check type="checkbox">
                    <Form.Check.Input type="checkbox" value={hasAgreedToTerms} onChange={(e) => setHasAgreedToTerms(e.target.checked)} />
                    <Form.Check.Label className="mx-1 d-inline">
                        I have read and agree to the&nbsp;
                        <a href="/static-pages/terms-and-conditions.html" target="_blank">Terms and Conditions</a>
                        &nbsp;and&nbsp;
                        <a href="/static-pages/privacypolicy.html" target="_blank">Privacy Policy</a>.
                    </Form.Check.Label>
                </Form.Check>
            </Form>
        )
    }

    function renderPayInvoiceButton(overrideButtonVariant, overrideButtonSize, overrideButtonText) {
        return (
            <div className="d-grid">
                <Button
                    variant={overrideButtonVariant || buttonVariant || "primary"}
                    size={overrideButtonSize || buttonSize}
                    onClick={(event) => handleLaunchPaymentForm(event)}
                >
                    {overrideButtonText || buttonText || defaultButtonText()}
                </Button>
            </div>
        );
    }

    function renderProcessingInvoiceButton() {
        return (
            <div className="d-grid">
                <Button
                    variant={buttonVariant || "primary"}
                    size={buttonSize}
                    onClick={(event) => handleConfirmationModalShow(event)}
                >
                    {buttonText || defaultButtonText()}
                </Button>
            </div>
        );
    }

    function renderDisabledPayInvoiceButton() {
        return (
            <div className="d-grid">
                <Button
                    variant={buttonVariant || "primary"}
                    size={buttonSize}
                    disabled
                >
                    {buttonText || defaultButtonText()}
                </Button>
            </div>
        );
    }

    function renderButton() {
        if (isButtonDisabled()) {
            return renderDisabledPayInvoiceButton();
        } else if (isSingleProcessingInvoice()) {
            return renderProcessingInvoiceButton();
        } else {
            return renderPayInvoiceButton();
        }
    }

    return (
        <span className={props.className}>
            {renderButton()}
            {showTermsAndConditions && renderTermsAndConditionsCheckbox()}
            {isSingleProcessingInvoice() && renderPendingPaymentConfirmationModal()}
        </span>
    );
};
