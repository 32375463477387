import { LOCAL, DEV, PROD, ENVIRONMENT } from '../utils/environment';

const LOCAL_API_URL = 'http://localhost:8000';
const DEV_API_URL = 'https://dev-api.msczone.com';
const PROD_API_URL = 'https://api.msczone.com';

function getBaseApi() {
    switch (ENVIRONMENT) {
        case LOCAL: return LOCAL_API_URL;
        case DEV:   return DEV_API_URL;
        case PROD:  return PROD_API_URL;
    }
    
    console.error(`Could not calculate environment for API`);
    return '';
}

export const BASE_API_URL = getBaseApi();
